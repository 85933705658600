<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      {{content}}
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Form35Poll',
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      onChangeIsModified: {
        type: Function,
        required: true,
      },
    },
    data: () => ({
      content: null,
    }),
    computed: {        
      ...mapState('app', ['locale']),
    },
    watch: {
      editedItem () {
        this.prepareContent()
      },
    },
    mounted () {
      this.prepareContent()
    },
    methods: {
      prepareContent () {
        this.content = this.editedItem
      },
      handleChangeIsModified () {
        this.onChangeIsModified(this.content) 
      },
    },
  }
</script>

